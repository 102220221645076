
export const localeToLang = (locale) => {
  switch (locale) {
    case 'zz':
      return 'sv'
    case 'no':
      return 'nb'
    default:
      return locale
  }
}
