/**
 * Same function as gtag
 * Usable when gtag isn't initialized
 */
export function pushDataLayer () {
  if (process.browser && !window.dataLayer) {
    window.dataLayer = []
  }
  window.dataLayer.push(arguments)
}

export const getUserConsentStatus = (rbcs) => {
  if (rbcs === undefined) {
    let jsonRbcs

    try {
      jsonRbcs = window.localStorage.getItem('rbcs')
    } catch {
      // localStorage no access or space
    }

    rbcs = jsonRbcs
      ? JSON.parse(jsonRbcs)
      : {}
  }

  return {
    all: rbcs.all || (rbcs.f && rbcs.a && rbcs.p),
    any: rbcs.all || rbcs.f || rbcs.a || rbcs.p,
    advertising: rbcs.a || rbcs.all,
    performance: rbcs.p || rbcs.all,
    functional: rbcs.f || rbcs.all,
    cookieVersion: rbcs.v,
    timestamp: rbcs.t
  }
}

export const trackPageView = (path, gtag) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[Tracking] Event PageView path: ${path}`)
  }

  if (typeof gtag === 'function') {
    gtag('pageview', path)
  }
}

export const trackViewContent = ({
  name,
  id,
  value,
  type = 'product',
  currency = 'SEK',
}, locale, gtag) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[Tracking] Event ViewContent name: ${name}`)
  }

  if (typeof gtag === 'function') {
    gtag('event', 'view_item', {
      currency,
      value,
      content_type: type,
      items: [{
        item_id: id,
        item_name: name,
        affiliation: locale
      }]
    })
  }

  if (typeof window.ttq?.track === 'function') {
    window.ttq.track('ViewContent', {
      value,
      currency,
      content_type: type,
      contents: [{
        content_id: id,
        content_name: name
      }],
    })
  }

  if (typeof window.fbq === 'function') {
    window.fbq('track', 'ViewContent', {
      value,
      currency,
      content_ids: [id],
      content_name: name,
      content_type: type
    })
  }
}

export const trackAddToCart = ({
  name,
  id,
  value,
  // quantity,
  // price,
  type = 'product',
  currency = 'SEK',
}, locale, gtag) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[Tracking] Event AddToCart name: ${name}`)
  }

  if (typeof gtag === 'function') {
    gtag('event', 'add_to_cart', {
      currency,
      value,
      content_type: type,
      items: [{
        item_id: id,
        item_name: name,
        affiliation: locale,
        // quantity,
        // price,
      }]
    })
  }

  if (typeof window.ttq?.track === 'function') {
    window.ttq.track('AddToCart', {
      value,
      currency,
      content_type: type,
      contents: [{
        content_id: id,
        content_name: name
        // quantity,
        // price,
      }],
    })
  }

  if (typeof window.fbq === 'function') {
    window.fbq('track', 'AddToCart', {
      value,
      currency,
      content_ids: [id],
      content_name: name,
      content_type: type
    })
  }
}

export const trackInitiateCheckout = ({
  value,
  id,
  name,
  currency = 'SEK',
  type = 'product'
}, locale, gtag) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[Tracking] Event InitiateCheckout name: ${name}`)
  }

  if (typeof gtag === 'function') {
    gtag('event', 'begin_checkout', {
      currency,
      value,
      items: [{
        item_id: id,
        item_name: name,
        affiliation: locale
      }]
    })
  }

  if (typeof window.ttq?.track === 'function') {
    window.ttq.track('InitiateCheckout', {
      content_type: type,
      value,
      currency,
      contents: [{
        content_id: id,
        content_name: name,
        affiliation: locale
      }],
    })
  }

  if (typeof window.fbq === 'function') {
    window.fbq('track', 'InitiateCheckout', {
      content_ids: [id],
      value,
      currency,
    })
  }
}
