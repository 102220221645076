import { useGtag } from '#imports'
import events from '@/constants/events'

export const useUserConsent = () => {
  const userConsentStatus = ref(getUserConsentStatus())

  /**
   * Set gtag consent mode from userConsentStatus
   */
  const refreshGtagConsent = () => {
    const { gtag } = useGtag()

    if (typeof gtag !== 'function') {
      return
    }

    const boolGrantedOrDenied = status => status ? 'granted' : 'denied'

    gtag('consent', 'update', {
      ad_storage: boolGrantedOrDenied(userConsentStatus.value.advertising),
      ad_user_data: boolGrantedOrDenied(userConsentStatus.value.advertising),
      ad_personalization: boolGrantedOrDenied(userConsentStatus.value.advertising),
      analytics_storage: boolGrantedOrDenied(userConsentStatus.value.performance)
    })
  }

  /**
   * Set localeStorage rbcs/user consent status
   * Create event that other pixels listen on and enable themselves based on status
   * Update gtag consent mode
   */
  const updateUserConsentStatus = (status, eventDetails) => {
    try {
      window.localStorage.setItem('rbcs', JSON.stringify(status))
    } catch {
      //
    }

    userConsentStatus.value = getUserConsentStatus(status)

    // Create event for pixels/trackers to listen on and enable on consent
    const event = new CustomEvent(events.rbcsChange, {
      detail: {
        ...eventDetails,
        userConsentStatus: userConsentStatus.value
      },
    })
    window.dispatchEvent(event)

    refreshGtagConsent()
  }

  return {
    userConsentStatus,
    updateUserConsentStatus,
    refreshGtagConsent
  }
}
