const minuteMS = 60000;
const hourMS = minuteMS * 60;

export default {
  shortDuration: minuteMS * 2,
  mediumDuration: minuteMS * 20,
  hour: hourMS,
  longDuration: hourMS * 3,
  day: hourMS * 12,
};
