import type { Pinia } from 'pinia'

import { createPersistedState } from '@/modules/persiststate/pinia-plugin'

export default defineNuxtPlugin((nuxtApp) => {
  const pinia = nuxtApp.$pinia as Pinia

  const locale = nuxtApp.ssrContext?.config?.public.locale
  const localePrefix = locale ? `${locale}_` : ''

  pinia.use(createPersistedState({
    storage: persistedState.localStorage,
    key: key => `${localePrefix}_${key}State`
  }))
})
