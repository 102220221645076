import {
  SOLRESOR_FALLBACK_HERO_ID,
  ROLFS_FALLBACK_IMAGE_ID,
  SOLRESOR_FALLBACK_IMAGE,
  ROLFS_FALLBACK_IMAGE
} from '@/constants/images'

export const useImages = () => {
  const isCharter = useLocaleStore().isCharter

  const localeAwareDefaultImage = (useHero = false) => {

    const hero = isCharter ? SOLRESOR_FALLBACK_HERO_ID : ROLFS_FALLBACK_IMAGE_ID
    const logo = isCharter ? SOLRESOR_FALLBACK_IMAGE : ROLFS_FALLBACK_IMAGE

    return useHero ? hero : logo
  }

  return {
    localeAwareDefaultImage
  }
}