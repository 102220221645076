import * as Sentry from '@sentry/vue'
import {
  extraErrorDataIntegration,
  rewriteFramesIntegration,
  captureConsoleIntegration,
  reportingObserverIntegration,
  httpClientIntegration,
} from '@sentry/integrations'

// import urls from '@/urls.json'
import { IGNORE_ERROR_TYPES } from '@/constants/sentry'

export default defineNuxtPlugin((nuxtApp) => {
  const { public: { sentry } } = useRuntimeConfig()

  if (!import.meta.browser){
    return
  }
  else if (
    !sentry.dsn ||
    sentry.envName === 'development'
  ) {
    return {
      provide: {
        sentrySetContext:
          (...params: Array<any>) => console.info('[Sentry] Set context: ', ...params),
        sentrySetUser:
          (...params: Array<any>) => console.info('[Sentry] Set user: ', ...params),
        sentrySetTag:
          (...params: Array<any>) => console.info('[Sentry] Set tag: ', ...params),
        sentryAddBreadcrumb:
          (...params: Array<any>) => console.info('[Sentry] Add Breadcrumb: ', ...params),
        sentryCaptureException:
          (...params: Array<any>) => console.error('[Sentry] Captured exception: ', ...params),
        sentryCaptureMessage:
          (...params: Array<any>) => console.error('[Sentry] Captured message: ', ...params)
      }
    }
  }

  const { vueApp } = nuxtApp
  const router = useRouter()
  Sentry.init({
    app: [vueApp],
    dsn: sentry.dsn,
    environment: sentry.environment,
    enabled: true,
    release: sentry.release,
    beforeSend: (event) => {
      try {
        if (
          /translate\.goog/.test(event?.request?.url || '') ||
          /translate\.goog/.test(event?.exception?.values?.[0]?.stacktrace?.frames?.[0].filename || '')
        ) {
          return null
        }
      } catch(error) {
        // console.error('Error in beforeSend:', error)
      }

      if (
        event &&
        typeof event.message === 'string' &&
        IGNORE_ERROR_TYPES.some(message => event.message?.startsWith(message))
      ) {
        return null
      }
      return event
    },
    ignoreErrors: IGNORE_ERROR_TYPES,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.dedupeIntegration(),
      extraErrorDataIntegration(),
      rewriteFramesIntegration(),
      reportingObserverIntegration(),
      captureConsoleIntegration(
        {
          levels: ['error'],
        }
      ),
      httpClientIntegration(),
      // new Sentry.Replay(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [/^localhost(:\d+)?$/, /^https:\/\/(www\.|dev\.)?rolfsbuss\.se$/, /^https:\/\/(www\.|dev\.)?solresor\.se$/, /^https:\/\/(www\.|dev\.)?rolfsreiser\.no$/],
  })

  vueApp.config.errorHandler = (error) => {
    Sentry.captureException(error)
    if(sentry.envName === 'development') {
      console.error(error)
    }
  }

  // TODO: remove and add this to init options instead of this mixin.
  vueApp.mixin(Sentry.createTracingMixins({
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  })),

  Sentry.attachErrorHandler(vueApp, {
    logErrors:
    false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
      sentryCaptureMessage: Sentry.captureMessage
    }
  };
})
