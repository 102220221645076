
export const LOCALES_DEFAULT_AIRPORT = {
  sv: {
    airport: 'GOT',
    airport_name: 'Göteborg-Landvetter'
  },
  no: {
    airport: 'OSL',
    airport_name: 'Oslo-Gardemoen'
  },
  zz: {
    airport: 'STO',
    airport_name: 'Stockholm'
  }
}

