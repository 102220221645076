import throttle from 'lodash/throttle';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      throttle: (fn: (...args: any[]) => any, wait: number) =>
        throttle(fn, wait),
    },
  };
});
