// fast-deep-equal plugin

import equal from 'fast-deep-equal';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      equal: (one: any, two: any) => equal(one, two),
    },
  };
});
