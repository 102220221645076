export default defineNuxtPlugin(() => {
/* eslint-disable */
  const config = useRuntimeConfig()
  if (config.public.ccId && config.public.envName === 'production') {
    window.setTimeout(() => {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = config.public.ccId;
      (function () {
        var d = document;
        var s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
      $crisp.push(['safe', true])
    }, 10000)
  }
})