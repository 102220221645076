
export const translationTypes = {
  i18n: 'locale',
  translation: 'translation'
}

export const createPageWideTranslation = (i18n = [], translation = [], data = {}) => ({
  [translationTypes.i18n]: i18n,
  [translationTypes.translation]: translation,
  translationData: data
})
