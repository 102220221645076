// currency.js plugin
import currency from 'currency.js';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      currency: (value: number, options: object) => currency(value, {...options, precision: 0, separator: '', pattern: `# !`}),
    },
  };
});
