import equal from 'fast-deep-equal'

export const toQueryParams = (params, includeStart) => {
  const queryParamsStr = Object.keys(params).map(k => `${k}=${encodeURIComponent(params[k])}`).join('&')

  return queryParamsStr && includeStart
    ? '?' + queryParamsStr
    : queryParamsStr
}

export const UrlEncoder = {
  /**
  * Encode a [deeply] nested object for use in a url
  * Assumes Array.each is defined
  */
  encode (params, prefix) {
    let items = []

    for (const field in params) {
      const key = prefix ? prefix + '[' + field + ']' : field
      const type = typeof params[field]

      switch (type) {
        case 'object':
          if (isEmpty(params[field])) {
            break
          }

          // handle arrays appropriately x[]=1&x[]=3
          if (Array.isArray(params[field])) {
            params[field].forEach(function (val) {
              items.push(key + '[]=' + val)
            }, this)
          } else {
            // recusrively construct the sub-object
            items = items.concat(this.encode(params[field], key))
          }
          break
        case 'function':
          break
        default:
          if (isEmpty(params[field])) {
            break
          }

          items.push(key + '=' + escape(params[field]))
          break
      }
    }

    return items.filter(Boolean).join('&')
  },

  /**
  * Decode a deeply nested Url
  */
  decode (params) {
    const obj = {}
    const parts = params.split('&')

    parts.forEach(function (kvs) {
      const kvp = kvs.split('=')
      let key = decodeURIComponent(kvp[0])

      const stringValue = unescape(kvp[1])
      let val = stringValue
      if (
        !stringValue.includes('-') &&
        !stringValue.includes('/') &&
        !stringValue.includes(',') &&
        !Number.isNaN(parseInt(stringValue))
      ) {
        val = parseInt(stringValue)
      } else if (stringValue === 'true') {
        val = true
      } else if (stringValue === 'false') {
        val = false
      }

      if (/\[\w+\]/.test(key)) {
        const rgx = /\[(\w+)\]/g
        const top = /^([^[]+)/.exec(key)[0]
        let sub = rgx.exec(key)

        if (!obj[top]) {
          obj[top] = {}
        }

        const unroot = function (o) {
          if (sub == null) {
            return
          }

          const subKey = sub[1]

          sub = rgx.exec(key)
          const isArray = /\[([0-9]+)?\]/.test(key)

          if (!o[subKey]) {
            o[subKey] = isArray ? [] : sub ? {} : val
          }

          if (isArray) {
            if (Array.isArray(o[subKey])) {
              o[subKey].push(val)
            }
          }

          unroot(o[subKey])
        }

        unroot(obj[top])

        // array
      } else if (/\[\]$/.test(key)) {
        key = /(^\w+)/.exec(key)[0]
        if (!obj[key]) {
          obj[key] = []
        }
        obj[key].push(val)
      } else {
        obj[key] = val
      }
    })

    return obj
  }
}

export const decodeUrlQuery = (url) => {
  const queryParamsString = decodeURIComponent(url?.split('?')?.[1] || '')
  return UrlEncoder.decode(queryParamsString)
}

/**
 * Used when keeping object as query params
 * - Filters out undefined/null/empty arrays/empty objects on each iteration (no matter previous value)
 * - Filters out default values since this is meant to be run on load, and not as main state
 * - Does not touch unrelated query params
 * @param {Object} targetObject
 * @param {String[]} queryObjectKeys
 * @param {String} path
 * @param {Boolean} clear
 * @returns {Object} updated and filtered properties. Any property not in queryObjectKeys kept untouched
 */
export const updateQueryObject = ({ targetObject, defaultObject, queryObjectKeys, path, clear, removeDefaults = true }) => {
  // Don't replace other unrelated params
  let allQueryParams = {}
  if (path) {
    allQueryParams = UrlEncoder.decode(path)
    queryObjectKeys.forEach(queryObjectKey => delete allQueryParams[queryObjectKey])
  }

  if (clear) {
    return allQueryParams
  }

  const backupProperties = deepClone(getObjectOnlyProperties(targetObject, queryObjectKeys))
  if (removeDefaults) {
    queryObjectKeys.forEach(
      (queryObjectKey) => {
        if (backupProperties[queryObjectKey] && typeof backupProperties[queryObjectKey] === 'object') {
          Object.keys(backupProperties[queryObjectKey]).forEach((deeperKey) => {
            if (equal(backupProperties[queryObjectKey][deeperKey], defaultObject[queryObjectKey][deeperKey])) {
              delete backupProperties[queryObjectKey][deeperKey]
            }
          })
        } else if (equal(backupProperties[queryObjectKey], defaultObject[queryObjectKey])) {
          delete backupProperties[queryObjectKey]
        }
      }
    )
  }

  return {
    ...allQueryParams,
    ...backupProperties,
  }
}

export const transformDictionaryToUrlStr = (dictionary, prefix = '?') => {
  const parseItem = (item) => {
    if (Array.isArray(item)) {
      return item?.join(',')
    }
    return item
  }
  const parts = Object.entries(dictionary || {})
    .map(([key, val]) => val ? `${key}=${parseItem(val)}` : null)
    .filter(Boolean)

  return `${prefix || ''}${parts.join('&')}`
}
