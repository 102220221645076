import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIAddressLocation, LazyIAppleStore, LazyIAspectRatioIcon, LazyICalendarBlank, LazyICheckmarkCard, LazyICheckmark, LazyICircleLineConnector, LazyIClock, LazyIClosebutton, LazyICross, LazyICrown, LazyIExclamation, LazyIExpand2, LazyIExpand, LazyIFlagSwe, LazyIGoogleStore, LazyIHeartGrey, LazyIHeartPink, LazyIHorizontalDashedLine, LazyIIncluded, LazyIInfo, LazyIMapmarkerBlueNew, LazyIMapmarkerBlueSimple, LazyIMapmarkerBlueWPin, LazyIMapmarkerBlueWStar, LazyIMapmarkerBlue, LazyIMapmarkerYellowWStar, LazyIMapmarkerYellow, LazyIMappoint, LazyIMarker, LazyIMastercard, LazyINoOpinionSelected, LazyINoOpinion, LazyIPeople, LazyIPlayButton, LazyIQuestmark404, LazyIShare, LazyISolDown, LazyISolresorEmpty, LazyISolresorFull, LazyISolresorHalf, LazyISolresorIcon, LazyIStarBoxEmpty, LazyIStarBoxFull, LazyIStarBoxHalf, LazyIStarEmpty, LazyIStarFull, LazyIStarHalf, LazyIStar0, LazyIStar1, LazyISuitcaseNo, LazyISuitcaseYes, LazyITripadvisorLogo, LazyITwistedArrowRightToTopIcon, LazyIValue, LazyIVerticalDashedLine, LazyIVisa, LazyIWave, LazyIX } from '#components'
const lazyGlobalComponents = [
  ["IAddressLocation", LazyIAddressLocation],
["IAppleStore", LazyIAppleStore],
["IAspectRatioIcon", LazyIAspectRatioIcon],
["ICalendarBlank", LazyICalendarBlank],
["ICheckmarkCard", LazyICheckmarkCard],
["ICheckmark", LazyICheckmark],
["ICircleLineConnector", LazyICircleLineConnector],
["IClock", LazyIClock],
["IClosebutton", LazyIClosebutton],
["ICross", LazyICross],
["ICrown", LazyICrown],
["IExclamation", LazyIExclamation],
["IExpand2", LazyIExpand2],
["IExpand", LazyIExpand],
["IFlagSwe", LazyIFlagSwe],
["IGoogleStore", LazyIGoogleStore],
["IHeartGrey", LazyIHeartGrey],
["IHeartPink", LazyIHeartPink],
["IHorizontalDashedLine", LazyIHorizontalDashedLine],
["IIncluded", LazyIIncluded],
["IInfo", LazyIInfo],
["IMapmarkerBlueNew", LazyIMapmarkerBlueNew],
["IMapmarkerBlueSimple", LazyIMapmarkerBlueSimple],
["IMapmarkerBlueWPin", LazyIMapmarkerBlueWPin],
["IMapmarkerBlueWStar", LazyIMapmarkerBlueWStar],
["IMapmarkerBlue", LazyIMapmarkerBlue],
["IMapmarkerYellowWStar", LazyIMapmarkerYellowWStar],
["IMapmarkerYellow", LazyIMapmarkerYellow],
["IMappoint", LazyIMappoint],
["IMarker", LazyIMarker],
["IMastercard", LazyIMastercard],
["INoOpinionSelected", LazyINoOpinionSelected],
["INoOpinion", LazyINoOpinion],
["IPeople", LazyIPeople],
["IPlayButton", LazyIPlayButton],
["IQuestmark404", LazyIQuestmark404],
["IShare", LazyIShare],
["ISolDown", LazyISolDown],
["ISolresorEmpty", LazyISolresorEmpty],
["ISolresorFull", LazyISolresorFull],
["ISolresorHalf", LazyISolresorHalf],
["ISolresorIcon", LazyISolresorIcon],
["IStarBoxEmpty", LazyIStarBoxEmpty],
["IStarBoxFull", LazyIStarBoxFull],
["IStarBoxHalf", LazyIStarBoxHalf],
["IStarEmpty", LazyIStarEmpty],
["IStarFull", LazyIStarFull],
["IStarHalf", LazyIStarHalf],
["IStar0", LazyIStar0],
["IStar1", LazyIStar1],
["ISuitcaseNo", LazyISuitcaseNo],
["ISuitcaseYes", LazyISuitcaseYes],
["ITripadvisorLogo", LazyITripadvisorLogo],
["ITwistedArrowRightToTopIcon", LazyITwistedArrowRightToTopIcon],
["IValue", LazyIValue],
["IVerticalDashedLine", LazyIVerticalDashedLine],
["IVisa", LazyIVisa],
["IWave", LazyIWave],
["IX", LazyIX],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
