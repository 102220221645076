
export const setCookie = (name, value, options ) => {
  if (process.browser) {
    document.cookie = [[name, value], ...Object.entries(options)]
      .map(([key, value]) => `${key}=${value}`).join(';')
  } else {
    //
  }
}

export const getCookie = (name) => {
  if (process.browser) {
    return document.cookie
      .split(';')
      .map(cookie => cookie.trim() && cookie.split('='))
      .find(cookie => cookie[0] === name)?.[0]
  } else {
    //
  }
}

export const cookieStrToObj = cookieString => cookieString
  .split(';')
  .reduce((cookieObj, cookie) => {
    const [key, value] = decodeURIComponent(cookie).trim().split('=')

    return {
      ...cookieObj,
      [key]: value
    }
  }, {})
