export const days = (trip) => {
  if (trip.days) {
    return trip.days
  }

  const daysMin = trip.days_min || 0
  const daysMax = trip.days_max

  return daysMin === daysMax ? daysMin : `${daysMin} - ${daysMax}`
}
