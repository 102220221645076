import { joinURL } from 'ufo'

import { setCookie, getCookie } from '@/utils/cookies'
import { API_DISALLOWED_FILETYPES } from '@/constants/api-fetch'

/**
 * @param {string} request Api path without env baseURL
 * @param {$fetch options} opts
 * @param {Boolean} throwOnFail Default true, if false, return error object instead of throwing
 * @param {Boolean} externalUrl Default false if true, request will be made to external URL use full URL as request
 * @returns {
 *   ...fetch response,
 *   data: data.json
 * }
 */
export const apiFetch = async (request, opts = {}, throwOnFail = true, externalUrl = false) => {
  // Prevent catch-all routes from trying to fetch files from API
  if (API_DISALLOWED_FILETYPES.some(filetype => request.endsWith(filetype))) {
    if (throwOnFail) {
      throw new Error('Not found: ' + request)
    }

    return {
      data: {},
      error: {}
    }
  }

  const { apiURL, baseURL } = useConfigStore()
  const rootStore = useRootStore()

  const headers = {}
  const options = {
    timeout: 60 * 1000,
  }

  const c = getCookie('rolfs_rf') || rootStore.rolfsCookie
  if (c) {
    headers['X-ROLFS-RF'] = c
  }

  if (!process.server) {
    headers['X-HREF'] = location.href
  }

  if (rootStore.agentCookie && rootStore.agentCookie.code) {
    headers['X-ROLFS-A'] = rootStore.agentCookie.code
    headers['X-ROLFS-ATIME'] = rootStore.agentCookie.currentDate
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(externalUrl ? `[apiFetch] Making external request to: ${request}` :`[apiFetch] Making request to: ${baseURL}${request}`)
  }

  options.headers = headers
  Object.assign(options, opts)

  const url = externalUrl
  ? request
  : process.browser
      ? `${baseURL}${request}` // PROXY
      : joinURL(apiURL, baseURL) + request // API URI

  let response = null
  try {
    response = await $fetch.raw(url, options)
  } catch (error) {
    if (process.NODE_ENV === 'development') {
      console.error('[apiFetch] Request failed: ', error)
    }

    if (throwOnFail) {
      throw error
    } else {
      return { error }
    }
  }

  if (response.headers.get('x-rolfs-rf')) {
    const c = response.headers.get('x-rolfs-rf')

    try {
      rootStore.SET_ROLFSCOOKIE(c)
      setCookie('rolfs_rf', { path: '/' })
    } catch (e) {
      // Cannot set headers after they are sent to the client
    }
  }

  return {
    ...response,
    data: response._data,
  }
}