// Translated
// Migrated
<template lang="pug">
component.b-skeleton.b-skeleton-animate-wave(
  :is="as"
  :style="aspectStyle"
  :class="typeClass"
  )
</template>

<script>
export default defineNuxtComponent({
  props: {
    height: {
      type: String,
      default: null
    },

    width: {
      type: String,
      default: null
    },

    type: {
      type: String,
      default: 'text'
    },

    as: {
      type: String,
      default: 'div'
    }
  },

  computed: {
    aspectStyle () {
      const style = {}

      if (this.height) {
        style.height = this.height
      }
      if (this.width) {
        style.width = this.width
      }

      return style
    },

    typeClass () {
      return `b-skeleton-${this.type}`
    },
  }
})
</script>
