// Translated
// Migrated
<template lang="pug">
.responsive-image.z-index-1.overflow-hidden(
  ref="container"
  :class="[`position-${position}`, { 'image-zoomable': zoomable }]"
  :data-loaded="loaded"
)
  .position-relative.w-100.h-100.z-index-1.responsive-image-img-container(v-if="!updating")
    b-skeleton-img.position-absolute.z-index-1.h-100.w-100.top-0.overflow-hidden(v-if="isLoading")
    img.z-1(
      v-else
      ref="thumbnailElement"
      v-bind="imageOptions(true)"
      :src="fallbackImageUrl",
    )
    img.z-2(
      v-if="!fallbackDidFail && srcset.length"
      ref="imageElement"
      v-bind="imageOptions()"
      :src="fallbackImageUrl",
      :srcset="srcset"
    )
  div.image-content.z-index-10(v-if="hasSlot && !subtext")
    slot

  div.pt-2(v-else-if="hasSlot && subtext")
    slot
</template>

<script>
import { mapState } from 'pinia'
import throttle from 'lodash.throttle'

export default defineNuxtComponent({
  setup () {
    const { localeAwareDefaultImage } = useImages()

    return {
      localeAwareDefaultImage,
    }
  },

  props: {
    lazy: {
      type: Boolean,
      default: true
    },

    alt: {
      type: String,
      required: true
    },

    image: {
      type: String,
      default: null
    },

    zoomable: {
      type: Boolean,
      default: false
    },

    cover: {
      type: Boolean,
      default: false
    },

    fit: {
      type: String,
      default: null
    },

    /**
     * Expects CSS image align values (e.g top, right)
     * Used for CSS image align and Cloudinary gravity (translated to cloudinary gravity value via cloudinaryUtils/alignToGravity)
     */
    gravity: {
      type: String,
      default: null
    },

    /**
     * CSS image align.
     * Defaults to @prop gravity when this isn't set
     */
    align: {
      type: String,
      default: null
    },

    /**
     * Any image provider. Can also be 'unknown'
     */
    type: {
      type: String,
      default: 'cloudinary'
    },

    preload: {
      type: Boolean,
      default: false
    },

    position: {
      type: String,
      default: 'relative'
    },

    skeleton: {
      type: Boolean,
      default: false
    },

    hero: {
      type: Boolean,
      default: false
    },

    /**
     * @prop {Boolean | 'height' | 'width'} clip
     * When true: clip to both width and height
     * When height: set image height to sizingRef/window height, and width to auto
     * When width: same but other way around
     */
    clip: {
      type: [Boolean, String],
      default: true
    },

    /**
     * Used to prevent fallback image to be visible behind partly transparent images
     */
    backgroundColor: {
      type: String,
      default: null
    },

    subtext: {
      type: Boolean,
      default: false
    },

    maxHeight: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data () {
    return {
      autoSizeSrc: null,
      fallbackImageUrl: null,
      fallbackDidFail: false,
      loaded: false,
      updating: false,
      srcSetData: []
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['isCharter']),
    isCloudinary () {
      return this.type === 'cloudinary'
    },

    isLoading () {
      if (this.skeleton) {
        return true
      }
      return !this.loaded && !this.fallbackImageUrl
    },

    defaultImage () {
      return this.localeAwareDefaultImage(this.hero)
    },

    hasSlot () {
      return slotHasContent('default', this.$slots)
    },

    srcset () {
      return this.srcSetData
        .map(({ image, size }) => `${image} ${size}`)
        .join(', ')
    },
  },

  watch: {
    image () {
      if (this.$refs.container) {
        this.$refs.container.className = this.$refs.container.className.replace(/--fallback-image/g, '')
      }

      this.resizeObserver?.disconnect()

      this.autoSizeSrc = null
      this.fallbackImageUrl = null
      this.loaded = false

      this.getFallbackImageUrl()

      if (this.isCloudinary) {
        this.autoSizeImage()
      }

      this.updating = true

      this.$nextTick(() => {
        this.updating = false
        this.resizeObserver = new ResizeObserver(this.autoSizeImage)
      })
    }
  },

  mounted () {
    if (this.isCloudinary) {
      this.autoSizeImage()

      this.resizeThrottle = throttle(this.autoSizeImage, 500)
      this.resizeObserver = new ResizeObserver(this.resizeThrottle)
      this.resizeObserver.observe(this.$refs?.container)
    }
  },

  beforeMount () {
    this.getFallbackImageUrl()
  },

  beforeUnmount () {
    this.resizeObserver?.disconnect()
  },

  methods: {
    getSizeAwareCloudinaryPath (imageId, customSuffix = '') {
      let w
      let h
      if (this.clip) {
        const size = findContainerAwareImageSize(this.$refs.container)
        w = this.clip === true || this.clip === 'width' ? size.w : undefined
        h = this.clip === true || this.clip === 'height' ? size.h : undefined
      }

      const custom = formatCloudinarySize(w, h, this.gravity)

      return formatCloudinaryImagePath(imageId, {
        transformation: { custom, customSuffix },
        isCharter: this.isCharter
      })
    },

    getFallbackImageUrl () {
      let imageUrl = 'default'

      if (this.fallbackDidFail) {
        imageUrl = 'default'
      } else if (!this.isCloudinary) {
        imageUrl = this.image
      } else if (this.isCloudinary) {
        imageUrl = formatCloudinaryImagePath(this.image, {
          transformation: { custom: formatCloudinaryThumbSize(this.gravity) },
          isCharter: this.isCharter
        })
      }

      const sizeAwareDefaultImage = () => this.hero
        ? this.getSizeAwareCloudinaryPath(this.defaultImage, ',q_10')
        : this.defaultImage

      if (imageUrl === 'default') {
        imageUrl = sizeAwareDefaultImage()
      }

      preloadImageUtil(imageUrl)
        .then(() => (this.fallbackImageUrl = imageUrl))
        .catch(() => {
          const fallbackSrc = sizeAwareDefaultImage()
          if (this.hero) {
            this.setupSrcSet(fallbackSrc)
          } else {
            this.fallbackImageUrl = fallbackSrc
          }
          this.fallbackDidFail = true
        })
        .finally(() => (this.loaded = true))
    },

    imageOptions (isFallback) {
      const classes = ['w-100 h-100 z-index-10 cover-image']
      const styles = []

      if (isFallback) {
        classes.push('position-absolute')
      } else {
        classes.push('position-relative')
      }
      if (this.align || this.gravity) {
        classes.push('object-align-' + this.align || this.gravity)
      }
      if (this.backgroundColor) {
        classes.push(`bg-${this.backgroundColor}`)
      }
      if(this.fit) {
        styles.push('object-fit-' + this.fit)
      }
      if(this.maxHeight) {
        styles.push(`max-height: ${this.maxHeight}px`)
      }
      return {
        loading: this.lazy ? 'lazy' : 'eager',
        class: classes.join(' '),
        style: styles.join('; '),
        alt: this.alt
      }
    },

    autoSizeImage () {
      if (!this.$refs.container || !this.image) {
        return ''
      }

      const imageId = removeImageType(this.image)

      this.setupSrcSet(imageId || this.defaultImage)
    },

    fallbackFailed () {
      this.fallbackDidFail = true
    },

    setupSrcSet (imageId) {
      const path = this.getSizeAwareCloudinaryPath(imageId || this.defaultImage)

      const regex = /,dpr_[0-9a-z]/i
      const sizes = [1, 2, 3].map(n => ({
        image: path.replace(regex, `,dpr_${n}`),
        size: `${n}x`
      }))

      this.srcSetData = sizes
    },
  }
})
</script>

<style lang="scss">
.article-block-img {
  position: relative;
}
.responsive-image {
  .image-content {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.image-zoomable {
    .responsive-image-img-container {
      img {
        transform: unset;
        transition: transform 200ms ease-out;
      }
    }
    &:hover {
      .responsive-image-img-container {
        img {
          transform: scale(1.08);
        }
      }
    }
  }
}
</style>
