import currency from 'currency.js'

const numberFormats = {
  default: 'sv',
  sv: {
    money: (value, options = {}) => currency(value, {
      symbol: ':-',
      precision: 0,
      separator: '\u2009',
      decimal: ',',
      pattern: '#!',
      negativePattern: '-#!',
      formatWithSymbol: true,
      ...options
    })
  },
  pl: {
    money: (value, options = {}) => currency(value, {
      symbol: 'zł',
      precision: 0,
      separator: '\u2009',
      decimal: ',',
      pattern: '#!',
      negativePattern: '-#!',
      formatWithSymbol: true,
      ...options
    })
  },
  no: {
    money: (value, options = {}) => currency(value, {
      symbol: ',-',
      precision: 0,
      separator: '\u2009',
      decimal: ',',
      pattern: '#!',
      negativePattern: '-#!',
      formatWithSymbol: true,
      ...options
    })
  },
  'zh-cn': {
    money: (value, options = {}) => currency(value, {
      symbol: '¥',
      precision: 0,
      separator: ',',
      decimal: '.',
      pattern: '!#',
      negativePattern: '-!#',
      formatWithSymbol: true,
      ...options
    })
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const locale = useLocaleStore().locale

  nuxtApp.vueApp.config.globalProperties.$filters = {
    currency(number, currency, forceSEK) {
      const l = numberFormats[locale] || numberFormats[numberFormats.default]
      let options = {}
      if (currency || forceSEK) {
        options = { symbol: currency, pattern: '# !' }
      }
      if (number < 0) {
        return '-' + l.money(parseFloat(-number), options).format()
      }
      return l.money(parseFloat(number), options).format()
    }
  }

  nuxtApp.vueApp.config.globalProperties.$n = (number, options = {}) => {
    const l = numberFormats[locale] || numberFormats[numberFormats.default]

    return l.money(parseFloat(number), options).format()
  }
})