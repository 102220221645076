// fontawesome plugin

import {
  library,
  config
} from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faArrowRightLong,
  faArrowRight,
  faArrowLeft,
  faStar,
  faCaretDown,
  faCaretSquareDown,
  faCaretSquareUp,
  faPlane,
  faEnvelope,
  faBedFront,
  faSuitcaseRolling,
  faBus,
  faSearch,
  faPlus,
  faPlusSquare,
  faPlusCircle,
  faMinus,
  faMinusCircle,
  faTimes,
  faPhone,
  faTag,
  faUser,
  faBan,
  faInfoCircle,
  faExclamationCircle,
  faBars,
  faMapMarkerAlt,
  faMapLocationDot,
  faMap,
  faSun,
  faUmbrellaBeach,
  faLocationDot,
  faCircleXmark,
  faCircle,
  faVideo,
  faClock,
  faCalendar,
  faHotel,
  faRotateLeft,
  faCheck,
  faQuoteLeft,
  faQuoteRight,
  faSunBright,
  faTaxiBus,
  faImage
} from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add({
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faArrowRightLong,
  faArrowRight,
  faArrowLeft,
  faStar,
  faCaretDown,
  faCaretSquareDown,
  faCaretSquareUp,
  faPlane,
  faEnvelope,
  faBedFront,
  faSuitcaseRolling,
  faBus,
  faSearch,
  faPlus,
  faPlusSquare,
  faPlusCircle,
  faMinus,
  faMinusCircle,
  faTimes,
  faPhone,
  faTag,
  faUser,
  faBan,
  faInfoCircle,
  faExclamationCircle,
  faBars,
  faMapMarkerAlt,
  faMapLocationDot,
  faMap,
  faSun,
  faUmbrellaBeach,
  faLocationDot,
  faCircleXmark,
  faCircle,
  faVideo,
  faClock,
  faCalendar,
  faHotel,
  faRotateLeft,
  faCheck,
  faQuoteLeft,
  faQuoteRight,
  faSunBright,
  faTaxiBus,
  faImage
});

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('fa', FontAwesomeIcon);
  nuxtApp.vueApp.component('fa-layers', FontAwesomeLayers);
});
