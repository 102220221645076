
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/sv'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(duration)
dayjs.extend(customParseFormat)
dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: "sv"


dayjs.updateLocale("sv")
dayjs.locale('sv')


export default dayjs
