<template lang="pug">
NuxtLayout(:name="setLayout")
  .error_page
    .row.error_page_content.mt-3
      .col-md-12.d-flex.error_handle_section.p-4
        .error_question.mr-4.hide-mobile
          i-questmark-404(
            filled
            :font-controlled="false"
          )
        .error_handler
          p {{ $t('404suggestions') }}
          ul
            li._handle.mb-0
              i18n-t(
                data-i18n="404suggestionVisitStart"
                keypath="404suggestionVisitStart"
              )
                nuxt-link(to="/") {{ $t('startPage') }}
            li._handle.mb-0(v-if="!isCharter")
              i18n-t(
                data-i18n="404suggestionBrowseCalendar"
                keypath="404suggestionBrowseCalendar"
              )
                nuxt-link(:to="`${localeURLs.calendar}`") {{ $t('calendar') }}
            li._handle.mb-0 {{ $t('404suggestionSearch') }}
            li._handle.mb-0 {{ $t('404suggestionGetInspired') }}
      .col-md-12.px-0.mt-5
        AlgoliaSearchBar.mb-3
        Trips(v-if="!isCharter", :trips="gridTrips")
</template>

<script setup>

const rootStore = useRootStore()
const menuStore = useMenuStore()

const localeStore = useLocaleStore()
const { getLocaleMessages: localeMessages } = storeToRefs(localeStore)

const tripStore = useTripStore()
const { categoryStart: trips } = storeToRefs(tripStore)

const { t } = useI18n()
const { urls: localeURLs, isCharter } = useLocale()

defineProps({
  error: {
    type: Object,
    required: true
  }
})

useHead({
  title: () => `${t('404title')}`,

  script: [{
    innerHTML: () => localeMessages.organizationData,
    type: 'application/ld+json'
  }],

  meta: [
    {
      hid: 'author',
      name: 'author',
      content: () => t('siteTitleBrand')
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `${t('siteTitleBrand')}`
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => `${t('siteTitleBrand')}`
    }
  ]
})

const setLayout = computed(() => isCharter ? 'charter-page' : 'banner-default')

const firstTrip = computed(() => trips.value[0])

const gridTrips = computed(() => trips.value.filter(trip => trip.id !== firstTrip.value.id).slice(0, 9))

await useAsyncData(async () => {
  try {
    await Promise.all([
      tripStore.fetchCategoryStart,
      ...(localeStore.isCharter ? [] : [rootStore.fetchTripTypesSidebar()]),
      rootStore.fetchTopNavbar(),
      rootStore.fetchFooter(),
      rootStore.fetchAlert()
    ])
  } catch {
    //
  }

  rootStore.SET_PAGE_BANNER({
    title: t('404oops'),
    subtitle: t('404subtitle'),
    type: 'small'
  })

  menuStore.closeSideMenuActive()
})
</script>

<style lang="scss">
@import "assets/scss/modules/_error";
</style>
