import revive_payload_client_4sVQNw7RlN from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import floating_vue_EIcJ7xiw0h from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/.nuxt/floating-vue.mjs";
import plugin_Jozdw60ZsE from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import pwa_client_Eorgdr12jA from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import titles_dw2T9lEw4h from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_BqLkNUef68 from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_hVzfPTGtkG from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/modules/persiststate/plugin.ts";
import crispchat_client_0N3jRIAHHn from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/crispchat.client.js";
import currency_gntxDfkfZG from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/currency.ts";
import directives_client_Ki22XKxTeO from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/directives.client.js";
import equal_zYqlklPD4L from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/equal.ts";
import facebook_client_wDbwNcZzgr from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/facebook.client.js";
import fontawesome_cn2c4tOOHz from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/fontawesome.ts";
import fuse_i9gyROssM6 from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/fuse.ts";
import hotjar_client_khHI17zbiJ from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/hotjar.client.js";
import jwt_decode_9vMjnfAvtn from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/jwt-decode.ts";
import lodash_throttle_7ALq4gqGkE from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/lodash-throttle.ts";
import maska_UHaKf2z1iQ from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/maska.ts";
import mitt_client_JdecFmZkyR from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/mitt.client.js";
import number_format_3aXgR3LDe7 from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/number-format.js";
import process_zQhW2AquOa from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/process.ts";
import profit_metrics_client_3s9HfeEW6H from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/profit-metrics.client.js";
import sentry_client_shVUlIjFLk from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/sentry.client.ts";
import simplebar_bAadaKzsic from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/simplebar.ts";
import tiktok_client_bDUkrEtiwL from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/tiktok.client.js";
import tradedoubler_zz_client_7mun59Y6x5 from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/tradedoubler_zz.client.js";
import vue_multiselect_HsRHaAXy6m from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/plugins/vue-multiselect.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  floating_vue_EIcJ7xiw0h,
  plugin_Jozdw60ZsE,
  pwa_client_Eorgdr12jA,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_BqLkNUef68,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_hVzfPTGtkG,
  crispchat_client_0N3jRIAHHn,
  currency_gntxDfkfZG,
  directives_client_Ki22XKxTeO,
  equal_zYqlklPD4L,
  facebook_client_wDbwNcZzgr,
  fontawesome_cn2c4tOOHz,
  fuse_i9gyROssM6,
  hotjar_client_khHI17zbiJ,
  jwt_decode_9vMjnfAvtn,
  lodash_throttle_7ALq4gqGkE,
  maska_UHaKf2z1iQ,
  mitt_client_JdecFmZkyR,
  number_format_3aXgR3LDe7,
  process_zQhW2AquOa,
  profit_metrics_client_3s9HfeEW6H,
  sentry_client_shVUlIjFLk,
  simplebar_bAadaKzsic,
  tiktok_client_bDUkrEtiwL,
  tradedoubler_zz_client_7mun59Y6x5,
  vue_multiselect_HsRHaAXy6m
]