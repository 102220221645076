<template lang="pug">
NuxtLoadingIndicator(:color="loadingColor")
.position-relative(:class="wrapperClass")
  NuxtPage

  slot

  LazyCognitoContainer(v-if="locale === 'sv'")
  CookieConsent
  NewsletterPopup(v-if="locale === 'no'")

  AgentComponent
    LazyAgentCall
    LazyTranslationTools
</template>

<script setup>

useConfigStore()
const route = useRoute()
const { t } = useI18n()

const headers = useRequestHeaders(['user-agent'])
const userAgent = headers['user-agent'] || ''

onServerPrefetch(async () => {
  const rootStore = useRootStore()
  const localeStore = useLocaleStore()

  await rootStore.handleAgentCookie(route.query?.a)

  const fetchPromises = [
    rootStore.fetchAgentCall(),
    rootStore.fetchTopNavbar(),
    rootStore.fetchFooter(),
    rootStore.fetchAlert()
  ]

  if (!isMobileUserAgent(userAgent) && !localeStore.isCharter) {
    fetchPromises.push(rootStore.fetchTripTypesSidebar())
  }

  await Promise.allSettled(fetchPromises)
})

onMounted(async () => {
  if (isMobileUserAgent() && !isCharter) {
    await rootStore.fetchTripTypesSidebar(false)
  }
})

const rootStore = useRootStore()
const {
  modalActive,
} = storeToRefs(rootStore)
const { locale, isCharter } = useLocaleStore()

useHead({
  htmlAttrs: {
    lang: () => locale === 'zz' ? 'sv' : locale,
  },
})

useSchemaOrg([
  defineWebSite({
    description: t('homeDescription'),
    inLanguage: locale === 'zz' ? 'sv-SE' : locale === 'no' ? 'nb-NO' : 'sv-SE',
  })
])

const wrapperClass = computed(() => {
  const classes = []

  if (!modalActive.value) {
    classes.push('overflow-clip')
  }

  return classes.join(' ')
})

const loadingColor = computed(() => isCharter ? '#fecb21' : '#065BA2')
</script>