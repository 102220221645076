// Migrated
<template lang="pug">
.cursor-pointer.mb-0.position-absolute.top-0.z-3.modal-close-btn(
  v-rtg-tooltip="{ text: $t('close'), position: 'bottom' }"
  :class="[alignClasses, sizeClass, positionClass, shadow && 'drop-shadow-dark']",
  @click="$emit('click')"
)
  fa-layers
    fa(
      v-if="backgroundColor !== 'transparent'"
      icon="circle"
      :class="`text-${backgroundColor}`"
      transform="shrink-1"
    )
    fa(
      icon="circle-xmark"
      :class="`text-${color}`"
    )
</template>

<script>
export default defineNuxtComponent({
  props: {
    /**
     * @prop {'inside' | 'border' | 'outside'}
     */
    align: {
      type: String,
      default: 'inside'
    },

    color: {
      type: String,
      default: 'white'
    },

    backgroundColor: {
      type: String,
      default: 'transparent'
    },

    shadow: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'sm'
    },

    buttonLeft: {
      type: Boolean,
      default: false
    }
  },

  emits: ['click'],

  computed: {
    alignClasses () {
      if (this.align === 'border') {
        if (this.buttonLeft) {
          return 'mt-n3 ml-n3'
        }
        return 'mt-n3 mr-n3'
      }
      if (this.align === 'outside') {
        return 'mt-md-n5 mr-md-n5 pt-2 pr-2'
      }
      return ''
    },

    positionClass () {
      if (this.buttonLeft) {
        return 'left-0'
      }
      return 'right-0'
    },

    sizeClass () {
      if (this.size === 'lg') {
        return 'h1'
      }

      return 'h2'
    }
  }
})
</script>
