//TODO: move to composables

export const cloudinaryBaseUri = 'https://res.cloudinary.com/rolfs-flyg-buss'

export const formatCloudinaryImagePath = (publicId, opt = {}) => {
  const { transformation, forceJPG, transform, isCharter } = {
    transformation: {},
    forceJPG: null,
    transform: true,
    isCharter: false,
    ...opt
  }

  if (!transform) {
    return `${cloudinaryBaseUri}/image/upload/${publicId}`
  }

  let transformationStr

  if (forceJPG) {
    transformationStr = 'f_jpg,q_auto'
  } else {
    transformationStr = 'f_auto,q_auto'
  }

  if (transformation.squareCrop) {
    transformationStr = `${transformationStr},ar_1:1,c_fill`
  } else if (transformation.watermark) {

    const watermark = isCharter ? 'solresor-new' : 'rolfsbuss'

    transformationStr = `c_fill,w_1200,h_630/${transformationStr},l_wm:${watermark},g_north_west,x_60,y_60,w_240`
  } else if (transformation.custom) {
    transformationStr = `${transformationStr},${transformation.custom}`
  }

  return `${cloudinaryBaseUri}/image/upload/${transformationStr}/${publicId}`
}

export const formatCloudinaryVideoPath = (publicId) => {
  return `${cloudinaryBaseUri}/video/upload/${publicId}`
}

/**
 * Switch case for alignment values used in gravity property
 * For Cloudinary gravity values @see https://cloudinary.com/documentation/resizing_and_cropping#control_gravity
 */
const alignToGravity = (gravity) => {
  switch (gravity) {
    case 'top':
      return 'north'
    case 'bottom':
      return 'south'
    case 'left':
      return 'west'
    case 'right':
      return 'east'
    default:
      return gravity
  }
}

// gravity = 'auto' = AI "pick the most interesting"
export const formatCloudinarySize = (w, h, gravity, dpr) => {
  let custom

  if (!w || !h) {
    custom = [
      gravity ? `g_${alignToGravity(gravity)},c_fill` : 'c_fill',
      `dpr_${dpr || parseDpr()}`
    ]

    if (w) {
      custom.push(`w_${w}`)
    } else if (h) {
      custom.push(`h_${h}`)
    }

    custom = custom.join(',')
  } else {
    custom = [
      `h_${h}`,
      `w_${w}`,
      gravity ? `g_${alignToGravity(gravity)},c_fill` : 'c_fill',
      `dpr_${dpr || parseDpr()}`
    ].join(',')
  }

  return custom
}

export const formatCloudinaryThumbSize = gravity => [
  'h_30',
  'e_blur:100',
  gravity ? `c_fill,g_${alignToGravity(gravity)}` : 'c_limit',
].join(',')

export const imageIsCloudinary = url => (url || '').includes(cloudinaryBaseUri)
