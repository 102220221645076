const contexts = {
  success: 'success',
  error: 'error',
  info: 'info',
  feedback: 'feedback',
};

export default contexts;

export { contexts };
